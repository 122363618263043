.load-more-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 8rem 0;
}

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  33% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
}
.button {
  display: inline-flex;
  align-items: center;
  background: var(--primary-color);
  box-shadow: 0 0.3rem 0.2rem 0 var(--box-shadow1);
  border-radius: 0.4rem;
  height: 3.8rem;
  padding: 1rem 7rem;
  color: var(--color-white);
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-size: 1.2rem;
  text-transform: uppercase;
  text-decoration: none;
  transition: background 0.3s, transform 0.3s, box-shadow 0.3s;
  will-change: transform;
}
.button:hover {
  background: var(--tertiary-color);
  box-shadow: 0 0.4rem 1.7rem var(--box-shadow);
  transform: translate3d(0, -0.2rem, 0);
  cursor: pointer;
}
.button:active {
  box-shadow: 0 0.1rem 0.1rem 0 var(--box-shadow1);
  transform: translate3d(0, 0.1rem, 0);
}

.pulse {
  position: relative;
}
.pulse:before,
.pulse:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--pulse-color);
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  opacity: 0;
  margin: auto;
}
.pulse:before {
  animation: pulse 1.5s infinite linear;
}
.pulse:after {
  animation: pulse 2s 0.4s infinite linear;
}
.pulse:hover:before,
.pulse:hover:after {
  display: none;
}
