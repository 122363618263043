.blogList .blogList-list {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  background: var(--bg-color-dark);
  margin: 0.4rem 0;
  padding-left: 0.2rem;
  font-size: 1.2rem;
}

.blogList .blogList-list .blogList-image {
  width: 8rem;
}

.blogList .blogList-list .blogList-image img {
  width: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
}

.blogList-list .bloglist-status div {
  display: flex;
}

.blogList-list .bloglist-status p {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.blogList-list .bloglist-status p i {
  height: 2rem;
}

.blogList-list .bloglist-status p i svg {
  height: 100%;
}

.blogList-list .blogList-title {
  font-weight: 600;
}

.blogList-list .blogList-controls .edit {
  color: var(--primary-color);
  cursor: pointer;
}
.blogList-list .blogList-controls .delete {
  cursor: pointer;
  color: tomato;
}

.blogList-controls {
  width: 10rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
