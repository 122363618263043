.sidebar {
  height: 100%;
  width: 24rem;
  position: fixed;
  background-color: var(--color-line);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.sidebar .brand {
  /* color: ; */
  font-family: 'Montserrat', sans-serif;
  font-size: 2.56rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3.2rem 0;
  font-weight: 700;
}

.sidebar .side-links ul {
  margin-bottom: 4.8rem;
}

.sidebar .side-links ul .active-sidelink {
  border-right: 0.48rem solid var(--tertiary-color);
}

.sidebar .side-links ul .active-sidelink a {
  color: var(--tertiary-color);
}

.sidebar .side-links ul li {
  border-right: 0.48rem solid transparent;
  margin: 1.6rem 0;
  transition: all 0.3s ease-in-out;
}

.sidebar .side-links ul li a {
  margin: 0 4.8rem;
  font-size: 1.48rem;
  display: flex;
  gap: 1.6rem;
  line-height: 3.2rem;
  align-items: center;
}

.sidebar .side-links ul li:hover {
  border-right: 0.48rem solid var(--primary-color);
}
.sidebar .side-links ul li a:hover {
  color: var(--primary-color);
}

.sidebar .side-logo {
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.96rem;
  margin: 0 3.2rem;
  border-radius: 0.2rem;
  padding: 1.6rem;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  color: var(--link-color-light);
  box-shadow: 0 0 0.6rem 0.3rem var(--primary-color);
}

.sidebar .side-logo img {
  height: 2.2rem;
}

.sidebar .side-logo p {
  font-size: 1rem;
}
