.searchHeader {
  /* background-color: gold; */
  /* padding: 1em; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.search {
  width: 95%;
  border: 2px solid var(--box-shadow1);
  border-radius: 2em;
  overflow: hidden;
  font-size: 1rem;
  display: flex;
  flex-flow: row nowrap;
}

.search:focus-within {
  box-shadow: 2px 2px 5px var(--box-shadow);
}

.search__button,
.search__input {
  background-color: #fff;
  border: none;
  outline: none;
}

.search__input {
  padding: 1em;
  width: 85%;
}

.search__button {
  width: 15%;
  font-size: 1.5rem;
  padding-right: 0.5em;
  color: gray;
  display: grid;
  place-content: center;
}
