.dashboard-nav {
  display: flex;
  justify-content: space-between;
  background-color: var(--color-line);
  padding: 1rem 4rem;
}

.dashboard-nav .dashboard-profile {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  font-size: 1.6rem;
}

.dashboard-nav .dashboard-profile .dashboard-prof-pic img {
  height: 4.8rem;
  width: 4.8rem;
  border-radius: 3.2rem;
  object-fit: cover;
}
