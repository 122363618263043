:root {
  --primary-color: #74cc52;
  --secondary-color: #c0d836;
  --tertiary-color: #049977;

  --body-bg-color: #fff;

  --bg-color-dark: #f3f4f4;
  --color-white: #fff;

  --link-color: #03a9f5;
  --link-color-light: #717579;
  --link-color-dark: #091f35;

  --color-line: #efefef;

  --bg-tag-featured: rgba(0, 0, 0, 0.51);

  --nav-link-dark: #162020;

  --h1--color: #2d2e2e;

  --box-shadow1: rgba(0, 0, 0, 0.1);
  --box-shadow: rgba(0, 0, 0, 0.2);

  --pulse-color: rgba(255, 255, 255, 0.4);
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  background-color: var(--body-bg-color);
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  font-size: 1.6rem;
  letter-spacing: 0.02rem;
}

.container {
  max-width: 120rem;
  margin: 0 auto;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: var(--link-color);
}

a:active {
  color: var(--secondary-color);
}

input {
  outline: none;
  border: 0.1rem solid var(--box-shadow);
  cursor: pointer;
}

input[type='radio'] {
  height: 1.2rem;
  width: 1.2rem;
  margin-right: 0.5rem;
}

.custom-radio {
  /* hiding browser el */
  appearance: none;
  /* Safari support */
  -webkit-appearance: none;
  border: 0.2rem solid #fff;
  background-color: var(--bg-color);
  border-radius: 50%;
}

/* keyboard navigation focus appearance */
.custom-radio:focus-visible {
  outline-offset: 0;
}

#Draft {
  outline: 1px solid #999;
}

#Draft:hover {
  outline-color: var(--primary-color);
}

#Draft:checked {
  outline-color: var(--primary-color);
  background-color: var(--primary-color);

  animation: outline-checked;
  animation-duration: 0.1s;
  animation-iteration-count: 4;
  animation-direction: alternate;
  animation-timing-function: linear;
}

@keyframes outline-checked {
  0% {
    outline-offset: 0;
  }

  100% {
    outline-offset: -0.2rem;
  }
}
