.login {
  min-height: 94vh;
  padding: 20rem 9rem 4rem;
}

.login .login-form {
  display: flex;
  justify-content: center;
}

.login .login-form form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 40rem;
}

.login .login-form form input {
  color: var(--link-color-light);
  font-size: 1.4rem;
  line-height: 1.6rem;
  min-width: 28rem;
  margin: 1.1rem 0 2rem;
  padding: 1rem 1rem 1rem 2rem;
}

.login .login-form form button {
  /* display: inline-flex; */
  align-items: center;
  background: var(--primary-color);
  box-shadow: 0 0.1rem 0.2rem 0 var(--box-shadow1);
  border-radius: 0.4rem;
  height: 3.8rem;
  padding: 1rem 7rem;
  color: var(--color-white);
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-size: 1.2rem;
  text-transform: uppercase;
  text-decoration: none;
  transition: background 0.3s, transform 0.3s, box-shadow 0.3s;
  will-change: transform;
  border: none;
}

.login .login-form form button:hover {
  background: var(--tertiary-color);
  box-shadow: 0 0.4rem 1.7rem var(--box-shadow);
  transform: translate3d(0, -0.2rem, 0);
  cursor: pointer;
}

.login .login-form form button:active {
  box-shadow: 0 0.1rem 0.1rem 0 var(--box-shadow1);
  transform: translate3d(0, 0.1rem, 0);
}

.login .login-form form button:disabled {
  cursor: not-allowed;
  background-color: var(--link-color-light);
}
