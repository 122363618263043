.button {
  outline: none;
  border: 0.1rem solid var(--color-line);
}

.settingsTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.settingsTitle .settingsUpdateTitle {
  font-size: 3rem;
  margin-bottom: 2rem;
}

.settingsTitle .settingsDeleteTitle {
  font-size: 1.2rem;
  color: var(--color-white);
  cursor: pointer;
  background-color: tomato;
  padding: 1rem 3rem;
  border-radius: 0.5rem;
}

.settingsTitle .settingsDeleteTitle .delete-icon {
  margin-right: 1rem;
}

.settingsForm {
  display: flex;
  flex-direction: column;
}

.settingsForm .settingsProfilePic {
  display: flex;
  align-items: center;
  margin: 1rem 0;
}

.settingsForm .settingsProfilePic img {
  width: 7rem;
  height: 7rem;
  object-fit: cover;
  border-radius: 2rem;
}

.settingsForm .settingsProfilePic .settingProfileIcon {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  background-color: var(--tertiary-color);
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.2rem;
  cursor: pointer;
}

.settingsForm label {
  font-size: 2rem;
  margin-top: 2rem;
}

.settingsForm input {
  margin: 1rem 0;
  line-height: 3.5rem;
  padding-left: 1rem;
}

.settingsForm .button {
  display: inline-block;
  margin-top: 2.5rem;
}
