.nav-conatiner {
}

.Navbar {
  /* position: sticky; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--body-bg-color);
  border-bottom: 1px solid var(--color-line);
  height: 6rem;
  z-index: 50000;
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 120rem;
}

.Navbar > .nav-logo {
  width: 10rem;
  margin: 1.5rem;
}

.Navbar > .nav-items {
  display: flex;
  color: var(--nav-link-dark);
}

.Navbar > .nav-items > li {
  font-size: 1.6rem;
  text-decoration: none;
  margin: 1.5rem;
  position: relative;
  opacity: 0.9;
}

.Navbar > .nav-items > ::first-line:hover {
  opacity: 1;
}

.Navbar > .nav-items > li::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0.2rem;
  background: var(--color-white);
  transition: all 0.45s;
}

.Navbar > .nav-items > a:hover::before {
  width: 100%;
}

.Navbar > .nav-toggle {
  display: none;
}

a {
  color: var(--nav-link-dark);
}

@media (max-width: 768px) {
  .Navbar > .nav-items {
    position: absolute;
    top: 6rem;
    display: flex;
    flex-direction: column;
    background: var(--primary-color);
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    transition: all 0.45s;
  }

  .Navbar a {
    color: var(--color-white);
  }

  .Navbar > .nav-items > li {
    color: var(--color-white);
  }

  .Navbar > .nav-items > li::before {
    background: transparent;
  }

  .Navbar > .nav-items.open {
    transform: translateX(0);
    height: 100vh;
  }

  .Navbar > .nav-toggle {
    display: flex;
    width: 5rem;
    height: 5rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .nav-toggle > .bar {
    position: relative;
    width: 3.2rem;
    height: 0.2rem;
    background: var(--primary-color);
    transition: all 0.45s ease-in-out;
  }

  .nav-toggle > .bar::before,
  .nav-toggle > .bar::after {
    content: '';
    position: absolute;
    height: 0.2rem;
    background: var(--primary-color);
    border-radius: 0.2rem;
    transition: all 0.45s ease-in-out;
  }

  .nav-toggle > .bar::before {
    width: 2.5rem;
    transform: translateY(-0.8rem);
    right: 0;
  }

  .nav-toggle > .bar::after {
    width: 32px;
    transform: translateY(0.8rem);
  }

  .nav-toggle.open > .bar {
    transform: translateX(-4rem);
    background: transparent;
  }

  .nav-toggle.open > .bar::before {
    width: 32px;
    transform: rotate(45deg) translate(2.6rem, -2.6rem);
  }

  .nav-toggle.open > .bar::after {
    transform: rotate(-45deg) translate(2.6rem, 2.6rem);
  }
}
