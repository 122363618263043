.blogList {
  display: flex;
  flex-direction: column;
}

.blogList .bloglist-title {
  font-size: 3rem;
  margin-bottom: 2rem;
}

.blogList .blogList-filter {
  margin-bottom: 3rem;
}

.blogList .table {
  /* table-layout: auto;
  width: 100%; */
  /* table-layout: fixed; */
  width: 100%;
}

.blogList thead tr {
  display: flex;
  font-size: 1.3rem;
}

.bloglist-featuredImage {
  flex-basis: 13%;
}

.bloglist-status {
  flex-basis: 17%;
}

.bloglist-blogtitle {
  flex-basis: 59%;
}

.bloglist-manage {
  flex-basis: 11%;
  flex-direction: row;
}

.bloglist-manage span:nth-child(1) {
  margin-right: 0.7rem;
}

.bloglist-manage span:nth-child(2) {
  margin-left: 0.7rem;
}
