.newsletter-box {
  background-color: var(--bg-color-dark);
  padding: 5.6rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 36.4rem;
}

.newsletter-box .newsletter-title {
  flex-grow: 1;
  flex-basis: 42rem;
  padding: 0 7.2rem;
}

.newsletter-box .newsletter-input {
  flex-grow: 1;
  flex-basis: 42rem;
  padding: 0 7.2rem;
  padding-bottom: 2rem;
}

.newsletter-box .newsletter-title h3 {
  font-family: 'Open Sans', sans-serif;
  color: var(--h1--color);
  font-size: 3rem;
  margin-bottom: 1rem;
}

.newsletter-box .newsletter-title p {
  font-family: 'Poppins', sans-serif;
  color: var(--link-color-light);
  font-size: 1.3rem;
  letter-spacing: 0.021rem;
  padding-bottom: 2rem;
}

.newsletter-input label h3 {
  font-family: 'Poppins', sans-serif;
  color: var(--h1--color);
  font-size: 1.4rem;
}

.newsletter-input input {
  color: var(--link-color-light);
  font-size: 1.4rem;
  line-height: 1.6rem;
  min-width: 28rem;
  margin: 1.1rem 0 2rem;
  padding: 1rem 1rem 1rem 2rem;
}

.newsletter-input .newsletter-notice {
  font-family: 'Poppins', sans-serif;
  color: var(--link-color-light);
  font-size: 1.1rem;
  font-style: italic;
  letter-spacing: 0.021rem;
  font-weight: 600;
}

.newsletter-input .button {
  margin-top: 2rem;
  border: none;
}

.success-message {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--primary-color);
}
