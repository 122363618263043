.blog-posts {
  display: flex;
  flex-wrap: wrap;
  margin-top: 4rem;
  column-gap: 3rem;
  row-gap: 6rem;
  padding: 0 1.5rem;
}

.blog-posts .blog-post {
  font-family: 'Poppins', sans-serif;
  font-size: 1.4rem;
  flex-basis: 26rem;
  /* flex-basis: 36rem; */
  /* min-width: 30rem; */
  flex-grow: 1;
  text-align: left;
  color: var(--link-color-light);
  padding: 1rem;
  border: 0.1rem solid var(--bg-color-dark);
  border-radius: 0.4rem;
}

.blog-posts .blog-post:hover {
}

.blog-pic {
  /* flex-basis: 360px; */
  overflow: hidden;
}

.blog-pic img {
  width: 100%;
  object-fit: cover;
  margin-bottom: 2rem;
  transition: transform 0.5s ease-in;
}

.blog-pic img:hover {
  transform: scale(1.06);
}

.blog-content .blog-post-category {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 1rem;
  color: var(--link-color-light);
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.blog-content .blog-post-category span {
  margin-right: 0.4rem;
  background-color: var(--bg-color-dark);
  padding: 0.4rem 0.8rem;
  display: inline-block;
}

.blog-content .blog-post-title {
  font-family: 'Open Sans', sans-serif;
  color: var(--h1--color);
  margin-bottom: 2rem;
  font-size: 1.9rem;
  font-weight: 700;
}

.blog-content .blog-post-title:hover {
  cursor: pointer;
}

.blog-content .blog-post-content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 1.3rem;
}


.blog-content .blog-date-read {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.4rem;
  padding-right: 1rem;
  font-size: 1.2rem;
  font-weight: 300;
  letter-spacing: 0.03rem;
}

.blog-content .blog-date-read .blog-min-read span {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  padding-right: 0.4rem;
  column-gap: 0.6rem;
}

.blog-content .blog-date-read .blog-min-read span svg {
  display: inline-block;
  width: 2.4rem;
  height: 2rem;
}

.blog-content .blog-author-name {
  font-size: 1.2rem;
  margin-top: 0.6rem;
  color: var(--h1--color);
  font-weight: 600;
}

.blog-content .blog-author-name span {
  color: var(--primary-color);
  transition: color 0.4s ease;
  letter-spacing: 0.04rem;
  margin-left: 0.4rem;
}

.blog-content .blog-author-name span:hover {
  cursor: pointer;
  color: var(--link-color);
}
