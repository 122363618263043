.target-post {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12.8rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  justify-content: center;
  padding-bottom: 2rem;
}

.post-column {
  flex-grow: 1;
  padding-bottom: 2rem;
  border-bottom: 1px solid var(--color-line);
}

.featuredPost-column {
  flex-grow: 1;
}

.post-column-meta {
  text-align: center;
  margin-bottom: 2rem;
}

.post-column-meta .post-column-category {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.6rem;
  margin-bottom: 0.6rem;
  color: var(--link-color-light);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.post-column-meta .post-column-category span {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  padding-right: 0.4rem;
  column-gap: 0.6rem;
}

.post-column-meta .post-column-category > div {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.post-column-meta .post-column-category .cat-list {
  font-weight: 700;
  margin-right: 1.2rem;
  background-color: var(--bg-color-dark);
  padding: 0.4rem 0.8rem;
}

.post-column-meta .post-column-category span svg {
  display: inline-block;
  width: 2.4rem;
  height: 2.4rem;
  margin-left: 2rem;
}

.post-column-meta .post-column-category span p {
  font-weight: 400;
}

.post-column-meta .post-column-title {
  font-family: 'Open Sans', sans-serif;
  color: var(--h1--color);
  margin-bottom: 2rem;
  font-size: 4.9rem;
  font-weight: 700;
}

.post-column-meta .post-column-author {
  font-size: 1.4rem;
  margin-top: 0.6rem;
  color: var(--link-color-light);
  font-weight: 600;
}

.post-column-meta .post-column-author span a {
  color: var(--primary-color);
  transition: color 0.4s ease;
  letter-spacing: 0.04rem;
  margin: 0 0.6rem;
}

.post-column-meta .post-column-author span a:hover {
  cursor: pointer;
  color: var(--link-color);
}

.target-post .post-column {
  flex-basis: 80rem;
  max-width: 80rem;
  margin-bottom: 5rem;
}

.target-post .featuredPost-column {
  flex-basis: 34rem;
  color: var(--nav-link-dark) !important;
  max-width: 80rem;
  display: flex;
  justify-content: center;
  padding-left: 4.4rem;
  /* margin: 1.5rem; */
}

.post-column .post-column-image {
  flex-basis: 60rem;
}

.post-column .post-column-image img {
  width: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.featuredPost-column .featured-posts-list a {
  font-family: 'Poppins', sans-serif;
  color: var(--link-color-light);
  transition: color 0.2s ease;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
}

.featuredPost-column .featured-posts-list a:hover {
  color: var(--primary-color);
}

.featuredPost-column .featured-posts-list a span {
  padding-right: 2.2rem;
  font-size: 1.6rem;
}

.featuredPost-column .featured-posts-list li {
  padding: 0px 0px 20px 0px;
}

.featuredPost-column .featured-title {
  font-weight: 700;
  color: var(--h1--color);
  font-size: 1.4rem;
  text-transform: uppercase;
  margin-bottom: 2.6rem;
}
