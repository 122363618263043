.manage-blog-title {
  font-size: 3rem;
  margin-bottom: 2rem;
}

.addblog {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.addblog .stat-cat {
  display: flex;
  gap: 4rem;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.addblog .stat-cat .cat {
  flex-grow: 1;
}

.addblog .stat-cat .cat p {
  font-weight: 600;
  margin-bottom: 0.6rem;
}

.addblog .stat-cat .stat {
  flex-grow: 1;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

.addblog .stat-cat .stat p {
  font-weight: 600;
}

.addblog .stat-cat .stat .selectStatus input {
  margin-right: 1rem;
  margin-left: 0.5rem;
}

.addblog .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.addblog .title .title-span {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
}

.addblog .title .featured-span {
  margin-top: 2.8rem;
}

.addblog .title .featured-span label {
  margin-right: 1rem;
}

.addblog .title span label {
  font-weight: 600;
  margin-bottom: 0.6rem;
}

.addblog .title input {
  line-height: 3.5rem;
  padding-left: 1rem;
}
/*  */

/*  */
.addblog .featuredImage .featured-Pic {
  display: flex;
  align-items: center;
  margin: 1rem 0;
}

.addblog .featuredImage .featured-Pic img {
  width: 60rem;
  height: 25rem;
  object-fit: none;
  border-radius: 2rem;
}
.featuredImage .featured-Pic .featured-PicIcon {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  background-color: var(--tertiary-color);
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.2rem;
  cursor: pointer;
}
/*  */
.addblog .featuredImage label {
  font-weight: 600;
  margin-bottom: 0.6rem;
}

.addblog .featuredImage input {
  line-height: 3.5rem;
  padding-left: 1rem;
}

.addblog .button {
  margin-top: 2rem;
  border: none;
  display: inline-block;
}

/* ^^^^^^^^^^^^^^^^^^^^^^^^^^^ */
.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-family: 'Georgia', serif;
  font-size: 14px;
  padding: 15px;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

.css-b62m3t-container {
  position: relative;
  z-index: 80000;
  box-sizing: border-box;
}
