.footer-minimal {
}

.footer-minimal,
.footer-minimal a {
  font-size: 1.2rem;
  font-family: 'Open Sans', sans-serif;
  margin: 1rem 1rem;
  color: var(--link-color-light);
}

.footer-minimal a:hover {
  color: var(--link-color);
}
