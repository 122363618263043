.category-nav {
  display: flex;
  flex-wrap: wrap;
  row-gap: 2rem;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--color-line);
  margin-top: -3rem;
  padding: 4rem 1.5rem 0rem;
}

.btn-categories {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  column-gap: 1rem;
  row-gap: 1rem;
}

.category-nav .title {
  font-family: 'Open Sans', sans-serif;
  color: var(--h1--color);
  font-weight: 700;
  margin-right: 4rem;
}

.btn {
  max-width: 10rem;
  height: 2.5rem;
  border: 0.1rem solid var(--primary-color);
  border-radius: 0.5rem;
  color: var(--h1--color);
  position: relative;
  transition: 1s;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 1.8rem 0;
  font-size: 1.3rem;
  font-weight: 500;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn:hover {
  background: var(--primary-color);
  color: var(--color-white);
}

.active {
  background: var(--primary-color);
  max-width: 10rem;
  height: 2.5rem;
  border: none;
  border-radius: 0.5rem;
  color: var(--color-white);
  position: relative;
  transition: 1s;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 1.8rem 0;
}

.active:hover {
  background: var(--primary-color);
}
