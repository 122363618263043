.featured-posts {
  display: flex;
  justify-content: flex-end;
  position: relative;
  bottom: 1.5rem;
  margin-top: 6rem;
}

.featured-posts .featured-post-1 {
  margin: 1.5rem;
  order: 2;
  border-left: 1px solid var(--color-line);
  padding: 4.6rem 0 0 2.8rem;
  flex-basis: 40rem;
}

.featured-posts .featured-post-1 .featured-title {
  font-weight: 700;
  color: var(--h1--color);
  font-size: 1.4rem;
  text-transform: uppercase;
  margin-bottom: 2.6rem;
}

.featured-posts .featured-post-1 .featured-posts-list a {
  font-family: 'Poppins', sans-serif;
  color: var(--link-color-light);
  transition: color 0.2s ease;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
}

.featured-posts .featured-post-1 .featured-posts-list a span {
  padding-right: 2.2rem;
  font-size: 1.6rem;
}

.featured-post-1 .featured-posts-list li {
  padding: 0px 0px 20px 0px;
}

.featured-posts .featured-post-1 .featured-posts-list a:hover {
  /* color: var(--link-color-dark); */
  color: var(--primary-color);
}

@media (max-width: 768px) {
  .featured-posts {
    flex-direction: column;
  }

  .featured-posts .featured-post-1 {
    order: 1;
    border: none;
  }
}

@media (max-width: 768px) {
  .featured-posts .featured-post-1 {
    margin: 1.5rem 0 -92rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    order: 2;
    border-left: 1px solid var(--color-line);
    padding: 4.6rem 0.1rem 0 1.4rem;
    flex-basis: 126rem;
  }
}
