.featured-posts .featured-post-2 {
  margin: 1.5rem 0 0 1.5rem;
  order: 1;
  flex-grow: 4;
  display: flex;
  flex-wrap: wrap;
  padding-top: 4.6rem;
  margin-bottom: 6rem;
  flex-basis: 116rem;
}

.featured-posts .featured-pic {
  flex-basis: 400px;
  position: relative;
  flex-grow: 1;

  overflow: hidden;
}

.featured-posts .featured-pic p {
  position: absolute;
  background-color: var(--bg-tag-featured);
  padding: 0.6rem 1.8rem;
  color: white;
  letter-spacing: 0.06rem;
  border-radius: 4px;
  top: 14px;
  right: 20px;
  font-size: 12px;
}

.featured-posts .featured-pic img {
  width: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
  margin-bottom: 2rem;
  transition: transform 0.5s ease-in;
}

.featured-posts .featured-pic img:hover {
  transform: scale(1.06);
}

.featured-posts .featured-content {
  font-family: 'Poppins', sans-serif;
  font-size: 1.4rem;
  flex-basis: 400px;
  padding-left: 2rem;
  padding-right: 2rem;
  flex-grow: 1;
  text-align: left;
  color: var(--link-color-light);
}

.featured-content .post-category {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  margin-bottom: 1rem;
  color: var(--link-color-light);
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.featured-content .post-category span {
  margin-right: 0.4rem;
  background-color: var(--bg-color-dark);
  padding: 0.4rem 0.8rem;
  display: inline-block;
}

.featured-content .post-title {
  font-family: 'Open Sans', sans-serif;
  color: var(--h1--color);
  margin-bottom: 2rem;
}

.featured-content .post-title:hover {
  cursor: pointer;
}

.featured-content .post-content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}


.featured-posts .featured-content .date-read {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.8rem;
  padding-right: 1rem;
  font-size: 1.2rem;
  font-weight: 300;
  letter-spacing: 0.03rem;
}

.featured-posts .featured-content .date-read .min-read span {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  padding-right: 0.4rem;
  column-gap: 0.6rem;
}

.featured-posts .featured-content .date-read .min-read span svg {
  display: inline-block;
  width: 2.4rem;
  height: 2rem;
}

.featured-posts .featured-content .author-name {
  font-size: 1.2rem;
  margin-top: 0.6rem;
  color: var(--h1--color);
  font-weight: 600;
}

.featured-posts .featured-content .author-name span {
  color: var(--primary-color);
  transition: color 0.4s ease;
  letter-spacing: 0.04rem;
}

.featured-posts .featured-content .author-name span:hover {
  cursor: pointer;
  color: var(--link-color);
}

@media (max-width: 768px) {
  .featured-posts .featured-post-2 {
    margin: 0 1.5rem 5rem 1.5rem;
    order: 2;
    padding-top: 0;
    flex-basis: 0;
  }
}
