.catList-list {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  background: var(--bg-color-dark);
  margin: 0.4rem 0;
  padding-left: 0.4rem;
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
  font-size: 1.4rem;
}

.addCat {
  display: flex;
  flex-direction: column;
}

.addCat div {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.addCat button {
  margin-top: 2rem;
  border: none;
  display: inline-block;
}

.postCat {
  line-height: 3.5rem;
  padding-left: 1rem;
}
